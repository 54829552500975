<template>
  <svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M13.8333 17.3346H2.16667C1.72464 17.3346 1.30072 17.159 0.988155 16.8465C0.675595 16.5339 0.5 16.11 0.5 15.668V4.0013C0.5 3.55927 0.675595 3.13535 0.988155 2.82279C1.30072 2.51023 1.72464 2.33464 2.16667 2.33464H3.83333V0.667969H5.5V2.33464H10.5V0.667969H12.1667V2.33464H13.8333C14.2754 2.33464 14.6993 2.51023 15.0118 2.82279C15.3244 3.13535 15.5 3.55927 15.5 4.0013V15.668C15.5 16.11 15.3244 16.5339 15.0118 16.8465C14.6993 17.159 14.2754 17.3346 13.8333 17.3346ZM2.16667 7.33464V15.668H13.8333V7.33464H2.16667ZM2.16667 4.0013V5.66797H13.8333V4.0013H2.16667ZM12.1667 14.0013H10.5V12.3346H12.1667V14.0013ZM8.83333 14.0013H7.16667V12.3346H8.83333V14.0013ZM5.5 14.0013H3.83333V12.3346H5.5V14.0013ZM12.1667 10.668H10.5V9.0013H12.1667V10.668ZM8.83333 10.668H7.16667V9.0013H8.83333V10.668ZM5.5 10.668H3.83333V9.0013H5.5V10.668Z" :fill="color"/>
  </svg>
</template>

<script>
export default {
  name: 'CalendarIcon',
  props: {
    color: {
      type: String,
      default: '#FFFFFF'
    }
  }
}
</script>

<style>

</style>