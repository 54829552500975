<template>
  <div class="tnd-input-container">
    <label>
      <div class="tnd-input-label" v-if="inputLabel.length || isLabel">
        <slot name="label">
          {{ inputLabel }}
        </slot>
      </div>
      <div class="tnd-container" :class="inputStatus">
        <div class="tnd-datepicker" v-if="inputType === 'datepicker'">
          <div class="activator" @click="open = !open">
            <calendar-icon />
          </div>
          <datepicker
            v-model="val"
            minimum-view="year"
            :disabled="disabled"
            format="yyyy"
            :disabledDate="disabledDates"
            v-if="datepickerType == 'year'"
          ></datepicker>
          <date-picker
            class="datepicker"
            v-model="val"
            :disabled="disabled"
            :open.sync="open"
            :type="datepickerType"
            value-type="format"
            :format="datepickerFormat"
            :disabledDate="disabledDates"
            @change="$emit('change')"
            v-else
          />
          <input
            v-if="datepickerType !== 'year'"
            class="input-field"
            :type="type"
            :placeholder="placeholder"
            :value="val"
            disabled
            @change="$emit('change')"
             @keyup="$emit('keyup')"
          />
          <div class="close-btn" @click="val = ''">
            <close-icon color="#6F6F6F" />
          </div>
        </div>
        <div class="tnd-select" v-else-if="inputType === 'select'">
          <b-select class="select-field input-field" v-model="val" :disabled="disabled">
            <option
              v-for="(option, index) of options"
              :key="index"
              :value="option.value"
              :disabled="disabled"
            >
              {{ option.name }}
            </option>
          </b-select>
          <input
            class="input-field is-invisible"
            :type="type"
            :placeholder="placeholder"
            :value="val"
            disabled
            @change="$emit('change')"
            @keyup="$emit('keyup')"
          />
          <slot name="after-input">
            <select-icon />
          </slot>
        </div>
        <div class="tnd-dadata" v-else-if="inputType === 'dadata'">
          <vue-dadata
            :change="setAddressValue"
            :token="dadataApiKey"
            :autoload="true"
            class="input-field"
            :placeholder="placeholder"
          ></vue-dadata>
        </div>
        <div class="tnd-input" v-else-if="inputType == 'number'">
          <div class="before-input">
            <slot name="before-input" />
          </div>
          <input
            class="input-field"
            :type="type"
            :disabled="disabled"
            :placeholder="placeholder"
            v-model="val"
            :maxlength="maxLength"
            @keypress="isNumber($event)"
            @change="$emit('change')"
             @keyup="$emit('keyup')"
          />
          <div class="after-input">
            <slot name="after-input" />
          </div>
        </div>
        <div class="tnd-datepicker" v-else-if="inputType == 'year'">
          <div class="activator">
            <calendar-icon />
          </div>
          <input
            class="input-field"
            :type="type"
            :disabled="disabled"
            :placeholder="placeholder"
            v-model="val"
            :maxlength="maxLength"
            @keypress="isNumber($event)"
            @change="$emit('change')"
             @keyup="$emit('keyup')"
           
          />
          <div class="after-input">
            <slot name="after-input" />
          </div>
        </div>
        <div class="tnd-datepicker" v-else-if="inputType == 'price'">
          <div class="activator">
            <price-icon />
          </div>
          <input
            class="input-field"
            :type="type"
            :disabled="disabled"
            :placeholder="placeholder"
            v-model="val"
            :maxlength="maxLength"
            @keypress="isNumber($event)"
          />
          <div class="after-input">
            <slot name="after-input" />
          </div>
        </div>
          <div class="tnd-input" v-else-if="inputType == 'textarea'">
          <div class="before-input">
            <slot name="before-input" />
          </div>
          <textarea
            class="input-field"
            :type="type"
            :disabled="disabled"
            :placeholder="placeholder"
            v-model="val"
            :maxlength="maxLength"
            :col="col"
            @change="$emit('change')"
             @keyup="$emit('keyup')"
          />
          <div class="after-input">
            <slot name="after-input" />
          </div>
        </div>
        <div class="tnd-input" v-else-if="inputType == 'login'">
          <div class="before-input">
            <slot name="before-input" />
          </div>
          <input
            class="input-field"
            :type="type"
            :disabled="disabled"
            :placeholder="placeholder"
            v-model="val"
            :maxlength="maxLength"
            @keypress="isCorrectLogin($event)"
            @change="$emit('change')"
            @keyup="$emit('keyup')"
          />
          <div class="after-input">
            <slot name="after-input" />
          </div>
        </div>
        <div class="tnd-input" v-else-if="inputType == 'name'">
          <div class="before-input">
            <slot name="before-input" />
          </div>
          <input
            class="input-field"
            :type="type"
            :disabled="disabled"
            :placeholder="placeholder"
            v-model="val"
            :maxlength="maxLength"
            @keypress="isCorrectName($event)"
            @change="$emit('change')"
            @keyup="$emit('keyup')"
          />
          <div class="after-input">
            <slot name="after-input" />
          </div>
        </div>
        <div class="tnd-input" v-else-if="inputType == 'post'">
          <div class="before-input">
            <slot name="before-input" />
          </div>
          <input
            class="input-field"
            :type="type"
            :disabled="disabled"
            :placeholder="placeholder"
            v-model="val"
            :maxlength="maxLength"
            @keypress="isCorrectPost($event)"
            @change="$emit('change')"
             @keyup="$emit('keyup')"
          />
          <div class="after-input">
            <slot name="after-input" />
          </div>
        </div>
        <div v-else-if="inputType == 'phone'">
          <div class="before-input">
            <slot name="before-input" />
          </div>
          +
          <input
            class="input-field"
            :type="type"
            :disabled="disabled"
            :placeholder="placeholder"
            v-model="val"
            :maxlength="maxLength"
            @keypress="isNumber($event)"
            @change="$emit('change')"
             @keyup="$emit('keyup')"
          />
          <div class="after-input">
            <slot name="after-input" />
          </div>
        </div>
        <div v-else-if="inputType == 'vue-select'">
          <div class="before-input">
            <slot name="before-input" />
          </div>
          <v-select 
            class="select-field input-field" 
            v-model="val" 
            :disabled="disabled" 
            :options="options"
            :reduce="option => option.value"
            label="name"
            />
          <div class="after-input">
            <slot name="after-input" />
          </div>
        </div>

        <div class="tnd-input" v-else>
          <div class="before-input">
            <slot name="before-input" />
          </div>
          <input
            class="input-field"
            :type="type"
            :disabled="disabled"
            :placeholder="placeholder"
            v-model="val"
            @change="$emit('change')"
            @keyup="$emit('keyup')"
          />
          <div class="after-input">
            <slot name="after-input" />
          </div>
        </div>
      </div>
      <p
        v-if="error"
        class="error has-text-danger is-size-6-5 mt-1"
        v-html="error"
      ></p>
      <span class="status-message" :class="inputStatus">{{ message }}</span>
    </label>
  </div>
</template>

<script>
import CalendarIcon from '../icons/CalendarIcon.vue'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/locale/ru'
import CloseIcon from '../icons/CloseIcon.vue'
import VueDadata from 'vue-dadata'
import SelectIcon from '../icons/SelectIcon.vue'
import PriceIcon from '../icons/PriceIcon.vue'
import Datepicker from 'vuejs-datepicker'
import vSelect from 'vue-select'

vSelect.props.components.default = () => ({
  Deselect: {
    render: createElement => createElement('span', 'x'),
  },
  OpenIndicator: {
    render: createElement => createElement('span', '▼'),
  },
});

export default {
  components: {
    DatePicker,
    CalendarIcon,
    CloseIcon,
    VueDadata,
    SelectIcon,
    PriceIcon,
    Datepicker,
    vSelect
  },
  name: 'TndInput',
  props: {
    inputLabel: {
      type: String,
      default: ''
    },
    disabedFuture: {
      type: String,
      default: ''
    },
    isLabel: Boolean,
    placeholder: {
      type: String,
      default: ''
    },
    inputType: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: 'text'
    },
    col: {
      type: Number,
      default: 0
    },
    value: {
      type: [String, Date, Number],
      default: () => null
    },
    options: {
      type: Array,
      default: () => []
    },
    inputStatus: {
      type: String,
      default: ''
    },
    maxLength: {
      type: Number,
      default: 13
    },
    datepickerType: {
      type: String,
      default: 'datetime'
    },
    datepickerFormat: {
      type: String,
      default: 'YYYY-MM-DD HH:mm'
    },
    message: {
      type: String,
      default: ''
    },
    error: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    dadataApiKey: process.env.VUE_APP_DADATA_API_KEY,
    open: false,
  }),
  computed: {
    val: {
      get() {
        return this.value
      },
      set(newVal) {
        this.$emit('input', newVal)
      }
    }
  },
  methods: {
    disabledDates(date) {
      return (this.disabedFuture) ? date > new Date() : false;
    },
    setAddressValue(e) {
      this.val = e.value
    },
    isNumber: function (evt) {
      evt = evt ? evt : window.event
      const charCode = evt.which ? evt.which : evt.keyCode
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault()
      } else {
        return true
      }
    },
    isCorrectLogin: function (evt) {
      evt = evt ? evt : window.event
      const charCode = evt.which ? evt.which : evt.keyCode
      const englishAlphabetAndWhiteSpace = /^[-._\w]*$/
      if (
        charCode == 8 ||
        charCode == 37 ||
        charCode == 39 ||
        englishAlphabetAndWhiteSpace.test(evt.key)
      )
        return true
      evt.preventDefault()
      return false
    },
    isCorrectName: function (evt) {
      evt = evt ? evt : window.event
      const charCode = evt.which ? evt.which : evt.keyCode

        if (
          charCode === 32 || (charCode > 66 && charCode < 90) ||
          (charCode > 97 && charCode < 123) ||
          (charCode > 1040 && charCode < 1105)
        ) {
          return true
        } else {
          evt.preventDefault()
          return false
        }
      },
  isCorrectPost: function(evt){
      evt = evt ? evt : window.event
      const charCode = evt.which ? evt.which : evt.keyCode
    if (
          charCode === 32 || (charCode > 97 && charCode < 123) || (charCode > 66 && charCode < 90) ||
          (charCode > 1040 && charCode < 1105) || charCode === 45 ||
          charCode === 40 || charCode === 41) {
          return true
        } else {
          evt.preventDefault()
          return false
        }
  }
  }
}
</script>

<style lang="scss">
@import '@/assets/variables';
.tnd-input-container {
  position: relative;
  padding-bottom: 8px;

  .error {
    //position: absolute;
    line-height: 1rem;
  }

  .tnd-input-label {
    display: block;
    min-height: 31px;
    font-size: 14px;
    line-height: 1em;
    font-weight: 400;
    color: #6f6f6f; //TODO добавить цвет в переменну
  }
  .tnd-container {
    position: relative;
    display: flex;
    align-items: center;
    border: 1px solid #ededed; //TODO добавить цвет в переменную
    background: #fbfbfb; //TODO добавить цвет в переменную
    border-radius: 3px;
    padding: 1em;

    .tnd-datepicker {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .activator {
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 46px;
        height: 100%;
        background-color: $purple-bright;
        border-top-left-radius: 3px;
        border-bottom-left-radius: 3px;
      }
      .datepicker {
        bottom: 0;
        left: 0;
        position: absolute;
        visibility: hidden;
      }

      .input-field {
        padding-left: 2.5em;
      }

      .close-btn:hover {
        cursor: pointer;
      }
    }

    .tnd-select {
      display: flex;
      width: 100%;
      align-items: center;
      .activator {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
      }

      .select-field {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        .select {
          height: 100%;
          width: 100%;
          &::after {
            display: none;
          }

          select {
            height: 100%;
            width: 100%;
            border: none;
            padding-left: 1rem;
            background-color: transparent;
            &:hover,
            &:active,
            &:focus {
              border: none;
            }

            option {
              &:hover {
                background-color: $purple-bright;
              }
            }
          }
        }
      }
    }

    .tnd-dadata {
      display: flex;
      width: 100%;
      .vue-dadata__suggestions {
        margin-top: 1.25em;
        border: 1px solid #e4e4e4;
        box-sizing: border-box;
        border-radius: 3px;
        box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.15);
      }
    }

    .tnd-input {
      display: flex;
      width: 100%;
      .before-input {
        display: flex;
        justify-content: center;
        align-items: center;
        * {
          margin-right: 12px;
        }
      }

      .after-input {
        display: flex;
        justify-content: center;
        align-items: center;
        * {
          margin-left: 12px;
        }
      }
    }

    &.error {
      border-color: red; //TODO добавить цвет в переменную
    }

    &.success {
      border-color: green; //TODO добавить цвет в переменную
    }

    &:focus-within {
      background-color: #fff;
      border-color: $purple-bright;
      outline: none;
    }

    .input-field,
    input {
      background: transparent;
      border: none;
      outline: none;
      font-size: 1em;
      line-height: 1.2em;
      height: auto;
      flex: 1;
      &::placeholder {
        color: #c4c4c4; //TODO добавить цвет в переменную
      }

      &:focus {
        box-shadow: none;
      }

      &:-webkit-autofill,
      &:-webkit-autofill:hover,
      &:-webkit-autofill:focus,
      &:-webkit-autofill:active {
        transition: background-color 5000s ease-in-out 0s;
      }
    }
  }

  .status-message {
    position: absolute;
    font-size: 12px;
    right: 2px;
    bottom: 0;

    &.error {
      color: red; //TODO добавить цвет в переменную
    }

    &.success {
      color: green; //TODO добавить цвет в переменную
    }
  }
}
.vdp-datepicker {
  padding-left: 35px;
}
.vdp-datepicker__calendar .cell,
.vdp-datepicker__calendar header span {
  color: #757763;
}
.vdp-datepicker__calendar header .prev,
.vdp-datepicker__calendar header .next {
  text-indent: 0 !important;
}
.vdp-datepicker__calendar header .prev:after,
.vdp-datepicker__calendar header .next:after {
  display: none;
}
.vdp-datepicker__calendar .cell:not(.blank):not(.disabled).day:hover,
.vdp-datepicker__calendar .cell:not(.blank):not(.disabled).month:hover,
.vdp-datepicker__calendar .cell:not(.blank):not(.disabled).year:hover,
.vdp-datepicker__calendar .cell.year.selected {
  border-color: #a01b5b !important;
  background: #a01b5b;
  color: white;
}
</style>

<style lang="scss">
.vs__clear {
  border: none;
  background: transparent;
}
.tnd-container > div {
  width: 100%;
}
.vs__actions {
  position: absolute;
  top: 15px;
  right: 15px;
}
.vs__dropdown-menu {
  padding: 0;
  margin-left: -15px;
  margin-right: -15px;
  li{
    padding: 5px 15px;
    &:hover {
      background: #F7F7FF;
    }
  }
}
.vs__search {
  display: none !important;
}
.vs__selected-options {
  padding-right: 35px;
  min-height: 18px;
}
.vs__selected {
  overflow: hidden;
  display: block;
  max-height: 18px;
}
.select-field.vs--open {
  margin-bottom: -15px;
}
</style>