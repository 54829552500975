<template>
  <svg width="8" height="16" viewBox="0 0 8 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M4 0.800781L0 6.40078H8L4 0.800781ZM4 15.2008L8 9.60078H0L4 15.2008Z" :fill="color"/>
  </svg>
</template>

<script>
export default {
  name: 'SelectIcon',
  props: {
    color: {
      type: String,
      default: '#6F6F6F'
    }
  }
}
</script>

<style>

</style>