<template>
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M6.66602 17.5002H8.33268V15.0002H13.3327V13.3335H8.33268V11.6668H12.0827C14.3802 11.6668 16.2493 9.79766 16.2493 7.50016C16.2493 5.20266 14.3802 3.3335 12.0827 3.3335H7.49935C7.27834 3.3335 7.06637 3.42129 6.91009 3.57757C6.75381 3.73385 6.66602 3.94582 6.66602 4.16683V10.0002H4.16602V11.6668H6.66602V13.3335H4.16602V15.0002H6.66602V17.5002ZM8.33268 5.00016H12.0827C13.461 5.00016 14.5827 6.12183 14.5827 7.50016C14.5827 8.8785 13.461 10.0002 12.0827 10.0002H8.33268V5.00016Z" fill="white"/>
  </svg>
</template>

<script>
export default {
  name: 'PriceIcon',
  props: {
    color: {
      type: String,
      default: '#FFFFFF'
    }
  }
}
</script>

<style>

</style>
